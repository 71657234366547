<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :titleInfo="titleInfo" :isFullScreen="true"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      optionList: [
        
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-50/image-1.svg"),
        },
        
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-50/image-2.svg"),
        },
        
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-50/image-3.svg"),
        },
        
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-50/image-4.svg"),
        },
         {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-50/image-5.svg"),
        },
         {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-50/image-6.svg"),
        },
       
      ],

      imgList: [
        {
          id: 1,
          pinying: "qù zhèr",
          hanzi: "去这儿",
        },
        {
          id: 2,
          pinying: "qù nàr",
          hanzi: "去那儿",
        },
      ],
      titleInfo:{
        pinyin:"qù nǎr？",
        hanzi:"……去哪儿？"
      }
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











