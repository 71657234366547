<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/background-monkeyboat.svg"),
      gameIndex:50,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-banana.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-banana-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-boat.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-boat-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-boat-1.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-boat-found-1.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-lotus-leaf.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-lotus-leaf-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-moon.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-moon-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-wogou-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-50/stroke-wogou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
