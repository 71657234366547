<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="7"/>
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Nǐ qù nǎr (xià)",
        hanzi: "你去哪儿 (下)",
        english: "Where are you going? (II)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-49-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>