<template>
  <div class="game-container">
    <ListenGame
      :trumpetImg="trumpetImg"
      :allImgList="allImgList"
      :answerList="answerList"
      :chooseHanziList="chooseHanziList"
    />
  </div>
</template>

<script>
import ListenGame from "@/components/Course/GamePage/ListenGame";
export default {
  data() {
    return {
      answerList: [2, 7, 12, 16, 17, 24, 27, 31],
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-2.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/xuexiao.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-7.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/chaoshi.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-12.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/shangdian.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-16.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/chaoshi.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-20.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/xuexiao.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-23.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/shangdian.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-25.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/chaoshi.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-29.svg`),
          audioSrc: require("@/assets/audio/L1/5-Words/chaoshi.mp3"),
        },
      ],
    };
  },
  computed: {
    allImgList() {
      let imgArr = [];
      for (let i = 1; i <= 32; i++) {
        imgArr.push({
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-${i}.svg`),
          id: i,
        });
      }
      return [
        imgArr.slice(0, 4),
        imgArr.slice(4, 8),
        imgArr.slice(8, 12),
        imgArr.slice(12, 16),
        imgArr.slice(16, 20),
        imgArr.slice(20, 24),
        imgArr.slice(24, 28),
        imgArr.slice(28, 32),
      ];
    },
  },
  components: {
    ListenGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
